.navbar {
  padding: 20px;
}

.d-none {
  display: none;
}

#root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  background-color: #fafafa;
}

#root > header {
  flex: 0 1 auto;
}

#root > main {
  flex: 1 1 auto;
}

#root > footer {
  flex: 0 1 auto;
}

.section-title {
  position: relative;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
  font-weight: 600;
  line-height: 1.5;
}
.section-title:after {
  display: block;
  position: absolute;
  content: '';
  width: 40px;
  height: 5px;
  background: #f49c05;
  bottom: -15px;
  left: calc(50% - 20px);
}

.float-button {
  position: fixed !important;
  right: 25px;
  bottom: 25px;
  z-index: 10;
}

.section {
  padding: 2rem 1rem;
}