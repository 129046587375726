.report-form__form-wrapper {
    max-width: 600px;
    margin: 0 auto;
}
.report-form__cancel-button {
    margin-right: 15px;
    background-color: #e2e5ea;
}
.report-form .MuiFormControlLabel-root {
    display: flex;
    justify-content: space-between;
    margin-left: 0;
}
.report-form .MuiFormLabel-root {
    font-size: 1.125rem;
}
.report-form .MuiInputBase-input {
    font-size: 1.275rem;
}
.report-form .MuiFormControlLabel-label {
    font-size: 1.275rem;
}
.report-form__form-actions {
    display: flex;
    justify-content: space-between;
}
