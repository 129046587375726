/*.reports-list__item {*/
/*    display: block;*/
/*    padding: 15px 30px;*/
/*    background-color: #E7E7E8;*/
/*    border-left: 5px solid #7d3d8f;*/
/*    !*opacity: 0;*!*/
/*    !*-webkit-animation: new-item 1s ease-in-out forwards;*!*/
/*    !*-moz-animation: new-item 1s ease-in-out forwards;*!*/
/*    !*animation: new-item 1s ease-in-out forwards;*!*/
/*    !*-webkit-transition-timing-function: cubic-bezier(0, 0, 0.4, 1);*!*/
/*    !*-moz-transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);*!*/
/*    !*transition-timing-function: cubic-bezier(0.6, 0, 0.4, 1);*!*/
/*}*/
/*.reports-list__item:not(:last-child) {*/
/*    margin-bottom: 10px;*/
/*}*/
/*.reports-list__item-group:not(:last-child) {*/
/*    margin-bottom: 15px;*/
/*}*/
/*.reports-list__group-title {*/
/*    font-size: 1.125rem;*/
/*    color: #333;*/
/*    font-weight: 600;*/
/*    line-height: 1;*/
/*    margin-bottom: 5px;*/
/*}*/
/*@-webkit-keyframes new-item {*/
/*    0% {*/
/*        opacity: 0;*/
/*        -webkit-transform: translateY(-100px);*/
/*    }*/
/*    80% {*/
/*        opacity: 0;*/
/*    }*/
/*    100% {*/
/*        opacity: 1;*/
/*        -webkit-transform: translateY(0);*/
/*    }*/
/*}*/
/*@-moz-keyframes new-item {*/
/*    0% {*/
/*        opacity: 0;*/
/*        -webkit-transform: translateY(-100px);*/
/*    }*/
/*    80% {*/
/*        opacity: 0;*/
/*    }*/
/*    100% {*/
/*        opacity: 1;*/
/*        -webkit-transform: translateY(0);*/
/*    }*/
/*}*/
/*@keyframes new-item {*/
/*    0% {*/
/*        opacity: 0;*/
/*        -webkit-transform: translateY(-100px);*/
/*    }*/
/*    80% {*/
/*        opacity: 0;*/
/*    }*/
/*    100% {*/
/*        opacity: 1;*/
/*        -webkit-transform: translateY(0);*/
/*    }*/
/*}*/
