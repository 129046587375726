.reports-list__loader-wrapper {
    padding: 160px 0;
    display: flex;
    justify-content: center;
}

.reports-list__loader-wrapper .loader {
    height: 80px;
    width: 80px;
    border: 3px solid #c3c3c3;
    border-right-color: transparent;
    border-top-color: transparent;
}

.reports-list__list-wrapper {
    max-width: 900px;
    margin: 0 auto;
}

.reports-list__no-records {
    text-align: center;
    padding: 160px 0;
    font-size: 2rem;
    color: #333;
}

.reports-list__loading-trigger {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
}

.reports-list__loading-trigger .loader {
    height: 40px;
    width: 40px;
    border: 3px solid #3273dc;
    border-right-color: transparent;
    border-top-color: transparent;
}

.reports-list__day {
    background: #fff;
    padding: 15px 20px;
    border-radius: 4px;
    margin-top: 15px;
}

.reports-list__day:not(:last-child) {
    margin-bottom: 15px;
}

.reports-list__item {
    margin-bottom: 8px;
}

.reports-list__item-title {
    font-size: 1.275rem;
    font-weight: bold;
    color: #4a4a4a;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.reports-list__user-info {
    font-size: 1rem;
    font-weight: bold;
    color: #4a4a4a;
}

.reports-list__report-date {
    position: relative;
    padding-left: 25px;
    font-size: .875rem;
}

.reports-list__report-date:before {
    display: block;
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 0;
    top: calc(50% - 7.5px);
    background: #28a745;
}

.reports-list__report-date--warning:before {
    background: #ffc107;
}

.reports-list__report-date--error:before {
    background: #dc3545;
}

.reports-list__reason {
    font-size: 1rem;
}

.column--right {
    border-bottom: 1px solid;
}

@media all and (max-width: 575px) {
    .reports-list__loader-wrapper {
        padding: 160px 0;
    }
}

@media all and (max-width: 374px) {
    .reports-list__report-date {
        padding-left: 20px;
        font-size: 0.75rem;
    }

    .reports-list__reason {
        font-size: .875rem;
    }

    .reports-list__user-info {
        font-size: .875rem;
    }
}