.navbar {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.3);
}

.navbar-title {
    font-weight: bold;
    font-size: 1.5rem;
}

@media screen and (max-width: 1024px) {
  .navbar {
    display: block;
  }
}